<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}用户协议</h3>
      <p class="bold">公布时间：2023年08月03日</p>
      <p class="bold">生效时间：2023年08月03日</p>
      <p>第【1】版</p>
      <section>
        <h5>1.重要须知</h5>
        <p>
          1.1在您开始使用成都超开心信息科技有限公司所提供的Less产品和/或服务前，您应该详细阅读《Less用户协议》（以下称“本协议”）《Less个人信息保护政策》等相关协议和业务规则，并决定是否接受前述协议或业务规则。
        </p>
        <p>
          1.2请您务必审慎阅读、充分理解各条款内容，特别是限制或免除责任条款，以及开通或使用某项服务的单独协议。限制或免除条款等重要内容将以加粗形式提示您注意，请您重点阅读。
        </p>
        <p>
          1.3如您对本协议的任务内容表示异议、无法准确理解本条款或不同意接受本协议的，请您不要注册、登录或使用“{{
            appName
          }}”平台；若您对“{{
            appName
          }}”平台进行登录、下载、发布信息内容、使用等操作行为将被视为您已阅读并同意受本协议的约束。
        </p>
        <p class="bold">
          1.4如您未满十八周岁或已满十八周岁但存在其他不具备与用户行为相适应的民事行为能力，您应当在父母或监护人陪同下阅读本协议以及<a
            :href="privacyUrl"
            >《{{ appName }}个人信息保护政策》</a
          >的内容，并取得父母或监护人的同意。在前述情况下，您与您的父母或监护人应按照法律规定承担因此而产生的一切后果。
        </p>
        <p>
          1.5在您接受本协议之后，因国家政策、产品以及履行本协议的环境发生变化等因素，本协议可能需要进行修改，并有效代替原来的服务条款。超开心公司将及时在相应页面更新并向用户公告。若用户对修改后的协议有异议或无法理解修改后的协议内容的，请立即停止浏览或使用{{
            appName
          }}产品和/或服务，用户继续浏览或使用的行为，视为对修改后的协议予以认可。
        </p>
        <p>
          1.6超开心公司有权依{{ appName }}产品和/或服务或运营的需要单方决定，安排或指定其关联方或其他第三方继续运营“{{
            appName
          }}”平台。并且，就本协议项下涉及的某些服务，可能会由超开心公司的关联方或超开心公司认可的第三方向您提供。您知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。
        </p>
      </section>

      <section>
        <h5>2.定义</h5>
        <p>
          2.1用户或您：指“{{ appName }}”平台的注册用户；若“{{
            appName
          }}”平台产品或服务的实际使用人为注册用户的被监护人，则该等实际使用人也属于本协议项下的“您”“用户”。
        </p>
        <p>
          2.2“{{
            appName
          }}”平台：指成都超开心信息科技有限公司及其关联方开发、运营的互联网小组件平台，包括APP客户端、公众号、小程序等。
        </p>
        <p>2.3超开心公司：指{{ appName }}产品和/或服务的运营方成都超开心信息科技有限公司及其关联公司。</p>
        <p>
          2.4关联公司：指相互之间存在关联关系，关联关系是指开心音符科技有限公司控股股东、实际控制人、董事、监事、高级管理人员与其直接或者间接控制的企业之间的关系，以及可能导致“{{
            appName
          }}”平台利益转移的其他关系。
        </p>
        <p>
          2.5{{ appName }}产品和/或服务：指成都超开心信息科技有限公司及其关联公司通过“{{
            appName
          }}”平台向您提供的产品或服务，内容包括但不限于浏览、下载及管理小组件等基础服务，拍照、分享、发布信息内容等交互服务，以及会员订阅等增值服务。
        </p>
      </section>

      <section>
        <h5>3.{{ appName }}产品和/或服务</h5>
        <p>
          3.1您使用{{
            appName
          }}产品和/或服务，可以通过预装或从超开心公司、超开心公司已授权的第三方下载等方式获取现在或未来不定时开发的客户端应用程序。对于这些软件应用程序，超开心公司同意给予用户一项个人的、不可转让及非排他性的许可。您仅可为非商业目的而访问或使用{{
            appName
          }}产品和/或服务；若您并非从超开心公司或经超开心公司授权的第三方获取“{{
            appName
          }}”平台或访问不正确网站的，超开心公司无法保证非官方版本的“{{
            appName
          }}”平台的安全性及能够正常使用，您因此遭受的损失与超开心公司无关。
        </p>
        <p>
          3.2您理解，您使用{{
            appName
          }}产品和/或服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等装置），一旦您在您终端设备中打开“{{
            appName
          }}”平台，即视为您使用{{ appName }}产品和/或服务。为充分实现“{{
            appName
          }}”的全部功能，您可能需要将您的终端设备联网，您理解您应自行承担所需要的费用（如流量费、上网费等）。
        </p>
        <p>
          3.3超开心公司可能根据实际需要对收费服务的收费标准、方式进行修改和变更，也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，超开心公司将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
        </p>
        <p>
          3.4您在接受相关产品或服务时，应按照“{{
            appName
          }}”平台的要求提供相关信息或数据，并保证所提供的信息或数据的完整性、真实性、合法性和准确性。
        </p>
        <p>
          3.5“{{
            appName
          }}”平台中的某一特定服务或功能可能由第三方合作伙伴提供，我们将在相关页面内标注第三方合作伙伴的信息，您在使用特定服务或功能前请阅读并同意相关的协议。如因第三方合作伙伴提供的服务产生争议、损失或损害，由您自行与第三方解决。如您需要超开心公司协助的，超开心公司会尽力协助。
        </p>
        <p>
          3.6为更好的提升用户体验及服务，“{{ appName }}”平台将不定期提供{{
            appName
          }}产品和/或服务的更新或改变（包括但不限于网站升级、软件修改、升级、功能强化、开发新服务、软件替换等），您可根据需要自行选择是否更新相应的版本。
        </p>
        <p>
          3.7本协议其他条款未明示授权的其他一切权利仍由超开心公司保留，您在行使这些权利时须另外取得超开心公司的书面许可。超开心公司如果未行使前述任何权利，不构成对该权利的放弃。
        </p>
      </section>

      <section>
        <h5>4．用户帐号规则</h5>
        <p class="bold">
          4.1您知悉、理解并承诺，您所设置的帐号不得违反《网络信息内容生态治理规定》等国家法律法规及“{{
            appName
          }}”平台的相关规则，您的帐号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等或采取其他足以让人引起混淆的方式）开设帐号，不得恶意注册{{
            appName
          }}帐号（包括但不限于频繁注册、批量注册帐号等行为）。您在帐号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。超开心公司有权对您提交的注册信息进行审核，若发现存在上述情况，超开心公司有权对您的帐号采取限制性措施或收回您的账号。
        </p>
        <p class="bold">
          4.2{{ appName }}账号的所有权归成都超开心信息科技有限公司所有，用户完成申请注册手续后，仅获得{{
            appName
          }}账号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖{{
            appName
          }}账号或者以其他方式许可非初始申请注册人使用{{
            appName
          }}账号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用{{ appName }}账号。
        </p>
      </section>

      <section>
        <h5>5.用户个人信息保护</h5>
        <p>5.1{{ appName }}与您一同致力于您（即能够独立或与其他信息结合后识别您身份的信息）的保护。</p>
        <p>
          5.2保护用户个人信息是{{ appName }}的基本原则之一，在使用{{
            appName
          }}产品和/或服务的过程中，您可能需要提供您的个人信息（包括但不限于您的姓名、电话号码、位置信息、设备信息等），以便超开心公司向您提供更好的服务和相应的技术支持。超开心公司将依法保护您浏览、修改、删除相关个人信息以及撤回授权的权利，并将运用加密技术、匿名化处理等其他与{{
            appName
          }}产品和/或服务相匹配的技术措施及其他安全措施保护您的个人信息。
        </p>
        <p>
          5.3更多关于您个人信息保护的内容，请参看
          <a :href="privacyUrl">《{{ appName }}个人信息保护政策》</a>
        </p>
      </section>

      <section>
        <h5>6.用户行为规范</h5>
        <p>
          6.1 您未经超开心公司事先书面同意，不得利用{{
            appName
          }}产品和/或服务进行任何诸如发布广告、销售商品、进行调查的商业行为，或者进行任何非法侵害超开心公司利益的行为。
        </p>
        <p>
          6.2
          您不得利用本软件发布、传播违法信息、虚假信息，损害任何第三方的名誉权、隐私权、肖像权、知识产权等合法权益的信息，或者发布垃圾信息，广告信息，骚扰信息等不良信息。
        </p>
        <p class="bold">
          6.3您在{{ appName }}产品和/或服务中或通过{{
            appName
          }}产品和/或服务所传送的任何内容并不反映或代表超开心公司的观点或政策，您须对在“{{
            appName
          }}”平台上传信息的真实性、合法性、无害性、有效性等承担全部责任。与用户所传播信息相关的任何法律责任由用户自行承担，与超开心公司无关。
        </p>
        <p class="bold">
          6.4您不得对“{{
            appName
          }}”平台或者该软件运行过程中释放到任何终端中的数据及该软件运行过程中客户端与服务器端的交互数据进行复制、更改、修改、挂接运行或创作任何衍生作品，形式包括但不限于通过非超开心公司开发、授权或认可的三方兼容软件、插件、外挂，登录或使用{{
            appName
          }}产品和/或服务。
        </p>
        <p class="bold">
          6.5除非超开心公司同意，用户不可对{{
            appName
          }}产品和/或服务任何部分的任何信息进行复制、拷贝、出售。用户不得使用任何手段删除、修改“{{
            appName
          }}”平台展示的信息，不得对“{{ appName }}”平台进行反向工程、反向汇编、反向编译等。
        </p>
        <p class="bold">
          6.6您不得通过修改或伪造“{{
            appName
          }}”平台作品运行中的指令、数据、数据包，增加、删减、变动软件的功能或运行效果，不得将用于上述用途的软件通过信息网络向公众传播或者运营。
        </p>
        <p class="bold">
          6.7您不得进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账号；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏“{{
            appName
          }}”平台的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称。
        </p>
        <p class="bold">
          6.8您未经超开心公司许可，不得在未经明示许可的其他终端设备上下载、安装、使用{{
            appName
          }}产品和/或服务。包括但不限于电视盒、机顶盒、固定电话、无线上网机、游戏机、电视机、DVD机等。
        </p>
        <p class="bold">
          6.9您不得以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用{{ appName }}产品和/或服务。
        </p>
        <p class="bold">
          6.10您应遵守本协议的各项条款，正确、适当地使用{{
            appName
          }}产品和/或服务，如因您违反本协议中的任何条款，超开心公司有权终止对违约用户提供服务。同时，超开心公司保留在任何时候收回{{
            appName
          }}账号的权利。
        </p>
      </section>

      <section>
        <h5>7.法律责任</h5>
        <p class="bold">
          7.1针对您违反本协议或其他服务条款的行为，超开心公司有权独立判断并视情况采取限制帐号部分或者全部功能直至终止提供服务、永久关闭帐号等措施，对于因此而造成您无法正常使用帐号及相关服务、无法正常获取您帐号内资产或其他权益等后果，{{
            appName
          }}不承担任何责任。
        </p>
        <p class="bold">
          7.2因您违反本协议或其他服务条款规定使用{{
            appName
          }}产品和/或服务，引起第三方投诉或诉讼索赔的，您应当自行处理并承担可能因此产生的全部法律责任。因您的违法或违约等行为导致超开心公司及其关联公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿{{
            appName
          }}及其关联公司因此遭受的全部损失。
        </p>
        <p class="bold">
          7.3超开心公司尊重并保护用户及他人的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用{{
            appName
          }}产品和/或服务时上传（如有）的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，超开心公司有权在收到权利方或者相关方通知的情况下删除、屏蔽侵权内容，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁的处罚。针对第三方提出的全部权利主张，您应自行处理并承担可能因此产生的全部法律责任。
        </p>
      </section>

      <section>
        <h5>8.服务的变更、中断和终止</h5>
        <p>
          8.1您理解并同意，超开心公司提供的{{
            appName
          }}产品和/或服务是按照现有技术和条件所能达到的现状提供的。超开心公司会尽最大努力向您提供服务，确保服务的连贯性和安全性。<span
            class="bold"
            >您理解，超开心公司不能随时或始终预见和防范法律、技术以及其他风险，包括但不限于不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用{{
              appName
            }}产品和/或服务以及其他的损失和风险。</span
          >
        </p>
        <p class="bold">
          8.2您理解并同意，超开心公司为了整体服务运营、平台运营安全的需要，有权视具体情况决定服务/功能的设置及其范围修改、中断、中止或终止{{
            appName
          }}产品和/或服务。
        </p>
      </section>

      <section>
        <h5>9.广告</h5>
        <p>
          9.1您理解并同意，在您使用{{
            appName
          }}产品和/或服务过程中，超开心公司可能会向您推送具有相关性的信息、广告发布或品牌推广服务，且{{
            appName
          }}将在{{ appName }}产品和/或服务中展示{{
            appName
          }}产品和/或服务和/或第三方供应商、合作伙伴的商业广告、推广或信息（包括商业或非商业信息）。
        </p>
        <p>
          9.2如您不愿意接收具有相关性的广告，您有权选择拒绝，超开心公司将不会向您推送该广告相关广告，但可能向您推送其他类型的广告。
        </p>
        <p>9.3如您不愿意接收超开心公司推送通知服务的，您有权在手机系统通知管理中自行关闭该服务。</p>
        <p>
          9.4超开心公司依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的可靠性并为自己的判断行为负责。<span
            class="bold"
            >除法律法规明确规定外，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失，您应自行承担，超开心公司不予承担责任。</span
          >
        </p>
      </section>

      <section>
        <h5>10.知识产权</h5>
        <p>
          10.1超开心公司是“{{ appName }}”平台的知识产权权利人。“{{
            appName
          }}”平台的著作权、商标权、专利权、商业秘密等知识产权，以及与“{{
            appName
          }}”平台相关的信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，超开心公司享有上述知识产权，但其他相关权利人依照法律规定应享有的权利除外。
        </p>
        <p>
          10.2如因“{{
            appName
          }}”平台使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，超开心公司不承担任何责任。超开心公司不对第三方软件或技术提供客服支持，若用户需要获取支持，请与第三方联系。
        </p>
        <p>
          10.3您使用{{
            appName
          }}产品和/或服务，只能在本协议以及相应的授权许可协议范围使用超开心公司的知识产权，未经授权超越范围使用的将构成对超开心公司的侵权。
        </p>
        <p>
          10.4超开心公司将保护知识产权作为运营的重要原则之一。用户在使用{{
            appName
          }}产品和/或服务时发表/上传的文字、图片、视频、音频及直播表演均属于您原创或已获合法授权的内容，您使用{{
            appName
          }}产品和/或服务时上传、发布的任何内容的知识产权归属您或原属创作人所有。
        </p>
        <p>
          10.5您应确保对您在使用{{
            appName
          }}产品和/或服务期间发表、上传的全部内容拥有合法权利或取得相应的授权。如果第三方提出关于知识产权的异议，为了保护合法权利人的知识产权，超开心公司有权根据实际情况删除相关内容，并将第三方权利人的通知转达您。若您的地址不明、无法转送的，超开心公司有关将通知书通过站内信、在“{{
            appName
          }}”平台上公告等方式向您送达。
        </p>
        <p>10.6用户在使用{{ appName }}产品和/或服务时发布上传用户原创的信息，此部分信息的知识产权归用户。</p>
        <p>
          10.7若{{
            appName
          }}发布的信息以及其他用户上传、分享的信息有侵犯用户或第三人的知识产权或其他权利的，被侵权人可通过投诉通道反馈意见，其他用户也可随时向超开心公司举报。
        </p>
        <p class="bold">
          10.8若您认为自己的著作权/信息网络传播权被超开心公司或开心音符用户侵犯，请向{{
            appName
          }}发出权利通知书。超开心公司将根据中国法律法规和政府规范性文件采取措施删除被控作品内容或断开与被控作品的链接。
        </p>
        <p class="bold">
          10.9超开心公司根据前述原因删除相关内容的，被删除内容的提供者可以按照有关法律法规规定向超开心公司发出关于被删除内容不侵犯知识产权/合法权益的书面说明。超开心公司收到书面说明，{{
            appName
          }}可以恢复被删除的内容或链接，且依法对该恢复行为不承担法律责任。
        </p>
      </section>

      <section>
        <h5>11.免责声明</h5>
        <p class="bold">
          11.1超开心公司有权对您使用“{{ appName }}”平台的情况进行监督，如经由通知、举报等途径发现您有在使用{{
            appName
          }}产品和/或服务时违反任何本协议规定的嫌疑，超开心公司可依合理判断，对违反有关法律法规或本协议约定，或侵犯、妨害、威胁任何人权利或安全的内容，或者假冒他人的行为，超开心公司有权依法停止传输任何前述内容，并有权依自行判断对违反本条款的任何人士采取适当的法律行动，包括但不限于，从{{
            appName
          }}服务中过滤、屏蔽、删除具有违法性、侵权性、不当性等内容，终止违反者的成员资格，阻止其使用{{
            appName
          }}全部或部分服务，并且依据法律法规保存有关信息并向有关部门报告等。
        </p>
        <p class="bold">
          11.2 您使用{{
            appName
          }}产品和/或服务必须遵守国家有关法律和政策，维护国家利益，保护国家安全，并遵守本协议，对于您违法或违反本协议的使用而引起的一切责任，由您承担全部责任。
        </p>
        <p class="bold">11.3“{{ appName }}”平台同大多数互联网产品一样，易受到各种安全问题的困扰，包括但不限于：</p>
        <p class="bold">（1）用户透露详细个人资料，被不法分子利用，造成现实生活中的骚扰。</p>
        <p class="bold">（2）被哄骗、破译帐号密码。</p>
        <p class="bold">
          （3）下载安装的其它软件中含有木马或病毒，威胁到个人终端机器上的信息和数据安全，继而威胁对{{
            appName
          }}产品和/或服务的使用。
        </p>
        <p class="bold">对于发生上述情况的，用户应当自行承担责任。</p>
        <p class="bold">
          11.4您理解并同意，{{ appName }}产品和/或服务可能会受多种因素的影响或干扰，超开心公司不保证(包括但不限于)：
        </p>
        <ul>
          <li class="bold">（1）{{ appName }}产品和/或服务不受干扰，及时、安全、可靠或不出现错误；</li>
          <li class="bold">（2）用户经由“{{ appName }}”平台取得的任何软件、服务或其他材料符合用户的期望；</li>
          <li class="bold">（3）{{ appName }}产品和/或服务中任何错误都将能得到更正。</li>
        </ul>
        <p class="bold">
          11.5您理解并同意，“{{
            appName
          }}”平台服务是按照现有技术和条件所能达到的水平提供的服务。超开心公司会尽最大努力为您提供服务，确保服务的连贯性和安全性。<span
            class="bold"
            >在使用{{
              appName
            }}产品和/或服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害（如洪水、地震、台风等）、网络原因、战争、罢工、骚乱等。出现不可抗力情况时，超开心公司将努力在第一时间及时修复，但因不可抗力造成的暂停、中止、终止服务或造成的任何损失，超开心公司在法律法规允许范围内免于承担责任</span
          >
        </p>
        <p>
          11.6您理解并同意，本协议旨在保障遵守国家法律法规、维护公序良俗，保护用户和他人合法权益，超开心公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证超开心公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果您已经理解并同意自行承担。
        </p>
        <p>
          11.7在任何情况下，超开心公司均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因您使用{{
            appName
          }}产品和/或服务而遭受的利润损失，承担责任。<span class="bold"
            >除法律法规另有明确规定外，超开心公司对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您因使用{{
              appName
            }}产品和/或服务期间而支付给{{ appName }}的费用（如有）。</span
          >
        </p>
      </section>

      <section>
        <h5>12.其他</h5>
        <p>
          12.1本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。倘若本协议之任何规定因与中华人民共和国法律抵触而无效，则这些条款应在不违反法律的前提下按照尽可能接近本协议原条文目的之原则进行重新解释和适用，且本协议其它规定仍应具有完整的效力及效果。
        </p>
        <p class="bold">
          12.2若您与超开心公司发生任何争议，双方应尽量友好协商解决，协商不成，您同意应将争议提交至超开心公司住所地人民法院诉讼解决。
        </p>
        <p>12.3本协议中的标题仅为方便阅读而设，并不影响本协议中任何规定的含义或解释。</p>
        <p>
          12.4您和超开心公司均是独立的主体，在任何情况下本协议不构成超开心公司对您的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。
        </p>
      </section>

      <section>
        <h5></h5>
        <p></p>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'UserAgreement',
    components: {},
    props: {},
    data() {
      return {
        privacyUrl: `https://landing.chaokaixin.net/privacy-protocol-less`,
        appName: '',
        appNameMap: new Map([['less', { appName: 'Less' }]]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'less';

      this.appName = this.appNameMap.get(app_name).appName;
      document.title = `${this.appName}用户协议`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  i {
    font-style: italic;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
